import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';
import { ArticleContent } from "../style/Blog";
import CardClotere1 from "./CardClotere1";
import CardClotereSidebar from "./CardClotereSidebar";
import RecherchesFrequentes from "./RecherchesFrequentes";
import ArticlesSimilaires from "./ArticlesSimilaires";
import HeaderPost from './HeaderPost';
import BlocCta from "../components/BlocCta";


function ArticleRoleNotaire(args) {

    return (
        <>
            <Helmet>
                <title>Quels sont les documents nécessaires pour vendre un bien immobilier ?</title>
                <meta name="robots" content="index,follow" />
                <meta name="googlebot" content="index,follow" />
                <meta name='description' content='Découvrez les documents indispensables pour vendre ou acheter un bien immobilier, des diagnostics aux justificatifs, et préparez votre transaction en toute sérénité. ' />
                <meta property="og:url" content="https://clotere.fr/fiches-pratiques-notaire/document-de-vente-notaire" />
                <meta property="og:site_name" content="Clotere" />
                <meta property="og:image" content="https://clotere.fr/facebook-share-webp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HeaderPost titre="Quels sont les documents nécessaires pour vendre un bien immobilier ?" />

            <div>
                <ArticleContent>

                    <section className="content">
                        <Container>
                            <Row>
                                <Col className="col-sm-9 col-xs-12 article">

                                    <p>
                                        La vente d'un bien immobilier implique la préparation de divers documents essentiels pour assurer la transparence et la conformité légale de la transaction. <br />
                                        Voici une liste des principaux documents à fournir par le vendeur principalement, lors de la vente de votre propriété.
                                    </p>

                                    <h2>Documents à fournir par le vendeur</h2>
                                    <p>
                                        Pour finaliser la vente, le vendeur doit réunir les documents suivants :
                                    </p>
                                    <ul>
                                        <li><strong>Titre de propriété :</strong> Atteste que vous êtes le propriétaire légal du bien.</li>
                                        <li><strong>Dossier de diagnostics techniques (DDT) :</strong> Comprend les diagnostics obligatoires (DPE, amiante, plomb, etc.).</li>
                                        <li><strong>Documents relatifs à la copropriété :</strong> Règlement de copropriété, PV des assemblées générales, carnet d'entretien.</li>
                                        <li><strong>Justificatifs de charges et taxes :</strong> Avis de taxe foncière, charges de copropriété.</li>
                                        <li><strong>Attestation de situation hypothécaire :</strong> Confirme l'absence d'hypothèques ou de dettes.</li>
                                        <li><strong>Justificatifs de travaux et garanties :</strong> Factures, certificats de garantie décennale ou biennale.</li>
                                        <li><strong>Certificat de conformité ou permis de construire :</strong> Pour les travaux récents ou les extensions.</li>
                                    </ul>

                                    <h2>Documents à fournir par l'acheteur</h2>
                                    <p>
                                        L'acheteur doit également présenter certains documents pour valider son achat et rassurer le vendeur :
                                    </p>
                                    <ul>
                                        <li><strong>Pièce d'identité :</strong> Carte nationale d'identité, passeport ou titre de séjour en cours de validité.</li>
                                        <li><strong>Justificatif de domicile :</strong> Facture récente (électricité, gaz, eau) ou attestation d'hébergement.</li>
                                        <li><strong>Relevés de comptes bancaires :</strong> Pour prouver la capacité de financement de l'achat.</li>
                                        <li><strong>Offre de prêt bancaire :</strong> Si l'achat est financé par un crédit immobilier, l'offre de prêt validée est indispensable.</li>
                                        <li><strong>Livret de famille :</strong> En cas d'achat en couple ou pour préciser la situation familiale (mariage, PACS, enfants).</li>
                                        <li><strong>Acte de mariage ou contrat de mariage :</strong> Si applicable, pour clarifier le régime matrimonial en cas d'achat conjoint.</li>
                                        <li><strong>Attestation de situation professionnelle :</strong> Contrat de travail, bulletin de salaire récent ou autre justificatif prouvant la stabilité des revenus.</li>
                                        <li><strong>RIB :</strong> Pour effectuer les paiements ou virements nécessaires à la transaction.</li>
                                    </ul>
                                    <blockquote className="blog-quote">
                                    Clotere vous met à disposition dans votre espace sécurisé une liste pesonnalisée des documents à fournir en fonction de votre situation. <br/><Link to="/inscription/achat">Débutez gratuitement votre dossier de vente Clotere</Link>, obtenez la liste des documents nécessaires et trouvez le notaire pour vous représenter.
                                    </blockquote>

                                    <h2>Conclusion</h2>
                                    <p>
                                        Une transaction immobilière implique la préparation minutieuse de nombreux documents de la part des deux parties. L'acquéreur comme le vendeur doivent s'assurer de fournir les pièces nécessaires pour faciliter le travail du notaire et garantir la conformité de la transaction.
                                    </p>
                                </Col>
                                <Col className="col-sm-3 col-xs-12 sidebar hidden sm:block">
                                    <CardClotereSidebar />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </ArticleContent>
                <BlocCta />
                <RecherchesFrequentes />
                <ArticlesSimilaires title="Articles similaires" />
                <Footer />
            </div >
        </>
    );
}

export default ArticleRoleNotaire;