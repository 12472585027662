'use client'
import React, { useState, useEffect } from "react";
import { useMemberstack } from "@memberstack/react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu, MenuButton, MenuItem, MenuItems
} from '@headlessui/react'
import { Bars2Icon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom';
import Logo from './img/icn-logo.svg'

import { PlusGrid, PlusGridItem, PlusGridRow } from './annuaire/Plus-grid'
import { Container } from "./annuaire/Container";


function DesktopNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);

  return (
    <nav className="relative hidden lg:flex">
      <PlusGridItem className="relative flex">
        <Link
          to="/trouver-votre-notaire"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
          Trouvez votre notaire
        </Link>
        <Link
          to="/fiches-pratiques-notaire"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
          Fiches pratiques notaire <span role="img">💡</span>
        </Link>
        {!member &&
          <>
            <Link
              to="/connexion"
              className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
            >
              Connexion
            </Link>
          </>}
      </PlusGridItem>
      {member &&
        <>
          <Menu as="div" className="relative ml-3">
            <div>
              <MenuButton className="relative flex  text-grey-300 text-lg hidden sm:flex p-4">
                <span className="absolute" />
                <span className="sr-only">Ouvrir le menu client</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-emerald-600">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                </svg>
                {member.auth.email}
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <MenuItem>
                <Link to="/app/dashboard" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100">
                  Tableau de bord
                </Link>
              </MenuItem>
              <MenuItem>
                <a className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                  href="#"
                  onClick={() =>
                    memberstack.logout()
                      .then(({ data, type }) => {
                        window.location.replace('/');
                      })}
                >
                  Deconnexion
                </a>
              </MenuItem>
            </MenuItems>
          </Menu>
        </>
      }
    </nav>
  )
}

function MobileNavButton() {
  return (
    <DisclosureButton
      className="flex size-12 items-center justify-center self-center rounded-lg data-[hover]:bg-black/5 lg:hidden"
      aria-label="Open main menu"
    >
      <Bars2Icon className="size-6" />
    </DisclosureButton>
  )
}

function MobileNav() {
  const memberstack = useMemberstack();
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (member === null) {
      memberstack.getCurrentMember()
        .then(({ data: member }) => setMember(member))
    }
  }, []);
  return (
    <DisclosurePanel className="lg:hidden">
      <div className="flex flex-col gap-6 py-4">
        <Link
          to="/trouver-votre-notaire"
          target="blank"
          className="text-base font-medium text-gray-950"
        >
          Trouvez votre notaire
        </Link>
        <Link
          to="/fiches-pratiques-notaire"
          className="flex items-center px-4 py-3 text-base font-medium text-gray-950 bg-blend-multiply data-[hover]:bg-black/[2.5%]"
        >
          Fiches pratiques notaire <span role="img">💡</span>
        </Link>
        {!member &&
          <>
            <Link
              to="/connexion"
              className="flex items-center px-4 py-3 text-base font-medium text-gray-950 "
            >
              Espace client
            </Link>
          </>}
        {member &&
          <>
            <Link to="/app/dashboard" className="block   text-base font-medium text-gray-950">
              Tableau de bord
            </Link>
            <a className="block   text-base font-medium text-gray-950"
              href="#"
              onClick={() =>
                memberstack.logout()
                  .then(({ data, type }) => {
                    window.location.replace('/');
                  })}
            >
              Deconnexion
            </a>

          </>
        }
      </div>
      <div className="absolute left-1/2 w-screen -translate-x-1/2">
        <div className="absolute inset-x-0 top-0 border-t border-black/5" />
        <div className="absolute inset-x-0 top-2 border-t border-black/5" />
      </div>
    </DisclosurePanel>
  )
}

export function Navbar({ banner }) {
  return (
    <Container>
      <Disclosure as="nav" className="pb-12">
        <PlusGrid>
          <PlusGridRow className="relative flex justify-between">
            <div className="relative flex gap-6">
              <PlusGridItem className="py-3">
                <Link to="/" title="Home">
                  <img src={Logo} alt="clotere" />
                </Link>
              </PlusGridItem>
              {banner && (
                <div className="relative hidden items-center py-3 lg:flex">
                  {banner}
                </div>
              )}
            </div>
            <DesktopNav />
            <MobileNavButton />
          </PlusGridRow>
        </PlusGrid>
        <MobileNav />
      </Disclosure>
    </Container>
  )
}
