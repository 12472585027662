import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
const faqs = [
    {
      question: "Quand vais-je recevoir le montant de la vente ?",
      answer:
        "Dans un délai de 2 à 21 jours après la signature de l'acte de vente authentique.Le notaire qui détiens l'argent de la vente sur un compte séquestre, vous versera le montant de la vente (minoré des frais de notaire qui lui reviennent et des frais d'agence).",
    },
    {
        question: "Que se passe-t-il si l'acheteur n'obtiens pas son financement ?",
        answer:
          "Le compromis de vente prévois ce cas de figure qui malheureusement peux arriver. L'acheteur doit présenter minimum 3 refus de prêt pour que la clause suspensive d'obtention de prêt bancaire s'applique. En tant que vendeur vous pourrez reprendre le processus de 0 avec un autre acheteur.",
      },
      {
        question: "Est-ce que la vente peut-être annulé ?",
        answer:
          "A rediger.",
      },
      {
        question: "Qui paye les frais de notaire ?",
        answer:
          "Les frais de notaire sont à la charge de l'acheteur. Sauf si il a été convenu lors du compromis de vente que c'tait à vous, vendeur de les payer.",
      },
    // More questions...
  ]


function FaqVendeur(args) {

    return (
        <>
        <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
      <div className="mx-auto max-w-7xl px-6 py-4">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-lg font-medium leading-10 tracking-tight text-gray-900">Les questions les plus fréquentes en tant que vendeur</h2>
          <dl className="mt-3 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-4">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
                    </>
    );
}

export default FaqVendeur;