import React from "react";

const faqs = [
    {
      question: 'Est-ce que Clotere est payant ?',
      answer:
        'La plateforme Clotere est totalement gratuite pour l’acheteur et le vendeur. Les prestations payantes sont à régler directement aux notaires choisis.',
    },
    {
        question: 'Pourquoi Clotere est plus rapide ?',
        answer:
          'Clotere vous permet de regrouper tous vos échanges, documents et événements avec toutes les parties prenantes de votre transaction immobilière.Ainsi, en vous proposant des rappels automatiques, des fixations de rendez-vous en ligne…etc, vous signez plus rapidement l\'acte de vente !',
      },
      {
        question: 'Est-ce que Clotere est un notaire ?',
        answer:
          'Non ! Clotere est une plateforme de mise en relation entre des utilisateurs et des notaires.',
      },
      {
        question: 'Est-ce que Clotere est un système sécurisé ?',
        answer:
          'La confidentialité et la sécurité de vos données sont toujours protégées lorsque vous utilisez Clotere. Nous utilisons la technologie SSL ; Les serveurs Clotere sont situés dans des centres de données en France conformes aux normes ISO 27001 ; Aucune de vos données personnelles n’est visible ni stockée par l\'un de nos partenaires ou entreprises sous-traitantes, ou utilisée à des fins publicitaires ; Conformément à la loi RGPR, les utilisateurs peuvent corriger, bloquer ou supprimer leurs données personnelles à tout moment',
      },
    // More questions...
  ]

function Faq() {
    return (
        <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="lg:col-span-5">
            <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Foire aux questions
            </h2>
            <p className="mt-4 text-pretty text-base leading-7 text-gray-600">
                Vous n'avez pas trouvé de réponse à vos questions ?
              Contactez-nous directement {" "}
              <a href="mailto:contact@clotere.fr" className="font-semibold text-indigo-600 hover:text-indigo-500">
                contact@clotere.fr
              </a>{' '}
              
            </p>
          </div>
          <div className="mt-10 lg:col-span-7 lg:mt-0">
            <dl className="space-y-10">
              {faqs.map((faq) => (
                <div key={faq.question}>
                  <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
    );
}

export default Faq;