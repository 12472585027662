import React from "react";
import { Card} from "reactstrap";
import { Link } from 'react-router-dom';
import styled from "styled-components";


export const CardS = styled(Card)`
background: rgb(234 244 243) !important;
border-radius: 20px;
border: 0;
margin-bottom: 2rem;
margin-top: 2rem;
 color:#fff;
 display:flex;
 flex-direction:row!important;
 align-items:center;
 justify-content:space-between;
 padding: 2rem;
 .card-title{
    font-size:24px;
    color:black;
 }
 @media all and (max-width: 768px) {
    flex-direction:column!important;
    .card-title{
        font-size:18px;
     }
  }
`;


function RecherchesFrequentes() {
    return (
        <>
        {/** recherches fréquentes notaire */}
        <section className=" py-12 sm:py-18">
        <div className="container">
            <div className="overflow-hidden rounded-lg bg-emerald-50 shadow x-4 py-5 sm:p-6">
                <div className="p">
                    <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">Recherches fréquentes sur les notaires</h2>
                </div>
                <div class="flex flex-row gap-4 flex-wrap justify-start">
                <Link className="tag-recherche-notaire" to="/notaires-a-paris">Trouver un notaire à Paris</Link>
                <Link className="tag-recherche-notaire" to="/notaires-a-lyon">Trouver un notaire à Lyon</Link>
                <Link className="tag-recherche-notaire" to="/notaires-a-bordeaux">Trouver un notaire à Bordeaux</Link>
                <Link className="tag-recherche-notaire" to="/notaires-a-marseille">Trouver un notaire à Marseille</Link>
                <Link className="tag-recherche-notaire" to="/notaires-a-lille">Trouver un notaire à Lille</Link>
            </div>
            </div>
        </div>
    </section>
    </>
    );
}

export default RecherchesFrequentes;