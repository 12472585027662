import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from '../Navbar.js';
import Footer from '../components/Footer.js';
import { Gradient } from "../annuaire/Gradient.js";
import { Container } from "../annuaire/Container.js";
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { Helmet } from "react-helmet";
import RecherchesFrequentes from "./RecherchesFrequentes.js";

const postsImmobilier = [
    {
        id: 1,
        title: 'Le rôle du notaire dans la vente d’un bien immobilier',
        href: '/fiches-pratiques-notaire/role-du-notaire-en-immobilier',
        description: "Voici un tour d’horizon du rôle du notaire dans la vente d’un bien immobilier, de la signature du compromis de vente à l’acte authentique.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/Notaire-scaled.jpg?resize=1024%2C683&ssl=1',
        category: { title: 'Immobilier', href: '#' },
    },
    {
        id: 2,
        title: 'Un ou deux notaires pour votre achat immobilier ?',
        href: '/fiches-pratiques-notaire/un-ou-deux-notaires-immobilier',
        description: "Lors d'une vente immobilière, il est courant que l'acheteur et le vendeur aient chacun leur propre notaire. Cette pratique,bien que facultative, présente plusieurs avantages pour les deux parties et renforce la sécurité juridique de la transaction.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/Frame-54.png?w=775&ssl=1',
        category: { title: 'Immobilier', href: '#' },
    },
    {
        id: 3,
        title: 'Quels sont les documents nécessaires pour vendre un bien immobilier ?',
        href: '/fiches-pratiques-notaire/document-de-vente-notaire',
        description: "La vente d'un bien immobilier implique la préparation de divers documents essentiels pour assurer la transparence et la conformité légale de la transaction. Voici une liste des principaux documents à fournir lors de la vente de votre propriété.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2023/03/comment-choisir-son-notaire.webp?fit=751%2C500&ssl=1',
        category: { title: 'Immobilier', href: '#' },
    },
    // More posts...
]


function Blog(args) {

    return (
        <>
        <Helmet>
            <title>Clotere.fr simplifie votre passage chez le notaire | Donation, achat vente immobilière</title>
            <meta name="robots" content="index,follow"/>
            <meta name="googlebot" content="index,follow"/>
            <meta name='description' content='Vous souhaitez faire une donation, ou trouver votre notaire pour votre achat immobilier ? Clotere vous acompagne et vous explique tous les détails de votre passage chez le notaire.' />
            <meta property="og:url" content="https://clotere.fr/fiches-pratiques-notaire"/>
            <meta property="og:site_name" content="Clotere"/>
            <meta property="og:image" content="https://clotere.fr/facebook-share-webp"/>
            <meta property="og:type" content="website"/>
        </Helmet> 
            {/*header*/}
            <div className="relative rounded-4xl ">
                <Gradient className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5" />
                <Container className="relative">
                    <Navbar user={args.user}
                        banner={
                            <Link
                                to="/demo"
                                className="no-underline flex items-center gap-1 rounded-full bg-fuchsia-950/35 px-3 py-0.5 text-sm/6 font-medium text-white data-[hover]:bg-fuchsia-950/30"
                            >
                                👋 Votre notaire en clair !
                                <ChevronRightIcon className="size-4" />
                            </Link>
                        }
                    />

                    <div className="pb-0 pt-14 sm:pb-22 sm:pt-14 md:pb-38 md:pt-22  mb-4">
                        <h1 className="text-[50px]  font-display  font-medium tracking-tight text-gray-950 mb-4">
                            Tout ce qu'il faut savoir sur votre <br/>passage chez <span className="textHighlight">le notaire</span>
                            <br/><br/>
                        </h1>

                    </div>
                </Container>
            </div>
            {/** content */}
            <section className=" py-12 sm:py-18">
                <div className="container">
                    <div className="overflow-hidden rounded-lg bg-blue-50 shadow x-4 py-5 sm:p-6">
                        <div className="p">
                            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">Le notaire et l'immobilier</h2>
                            <p>Découvrez nos fiches pratiques détaillées sur les démarches essentielles chez le notaire pour réussir vos transactions immobilières en toute sérénité.</p>
                        </div>
                        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                            {postsImmobilier.map((post) => (
                                <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                    <div className="relative w-full">
                                        <img
                                            alt=""
                                            src={post.imageUrl}
                                            className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                        />
                                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                    </div>
                                    <div className="max-w-xl">
                                        <div className="mt-8 flex items-center gap-x-4 text-sm">
                                            <p
                                                className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                            >
                                                {post.category.title}
                                            </p>
                                        </div>
                                        <div className="group relative">
                                            <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                <Link to={post.href} className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                    <span className="absolute inset-0" />
                                                    {post.title}
                                                </Link>
                                            </h3>
                                            
                                            <Link to={post.href} className="btn-primary no-underline">
                                                Lire la suite
                                            </Link>
                                        </div>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>

                </div>
            </section>

            {/** faq notaire */}
            <section className=" py-12 sm:py-18">
                <div className="container">
                    <div className="overflow-hidden rounded-lg bg-emerald-50 shadow x-4 py-5 sm:p-6">
                        <div className="p">
                            <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">Questions fréquentes sur le notaire</h2>
                            <p>Toutes les questions que vous vous posez sur votre passage chez le notaire</p>
                        </div>
                        <div class="flex flex-row gap-4 flex-wrap justify-start">
                        <Link className="tag-recherche-notaire" to="/fr/notaires/ville/paris">Qu'est-ce que les frais de notaires ?</Link>
                        <Link className="tag-recherche-notaire" to="/fr/notaires/ville/lyon">À quel moment dois-je payer les frais de notaire ?</Link>
                        <Link className="tag-recherche-notaire" to="/fr/notaires/ville/lille">Suis-je obligé de prendre un notaire pour mon achat immobilier ?</Link>
                        <Link className="tag-recherche-notaire" to="/fr/notaires/ville/bordeaux">Les clauses suspensives qu'est-ce que c'est ?</Link>
                        <Link className="tag-recherche-notaire" to="/fr/notaires/ville/rennes">Puis-je changer de notaire en cours de transaction ?</Link>
                    </div>
                    </div>
                </div>
            </section>

            <RecherchesFrequentes/>
            <Footer />

        </>
    );
}

export default Blog;