import React from "react";
/** composants **/
import { Navbar } from './Navbar';
import Footer from './components/Footer';
import { Container } from "./annuaire/Container";
import Faq from './components/Faq';
import BlocCta from './components/BlocCta';
import { CheckIcon} from "@heroicons/react/20/solid";
import { useNavigate } from 'react-router-dom';


const posts = [
    {
        id: 1,
        title: 'Les différents types de donation',
        href: 'https://clotere.com/quelles-sont-les-differentes-formes-de-donation/',
        description: "La donation est un acte par lequel une personne transfère gratuitement la propriété d’un bien à une autre personne.Voici un tour d’horizon des différentes formes de donation et du rôle du notaire.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/clauses-suspensives.webp?resize=600%2C400&ssl=1',
        category: { title: 'Type de donation', href: '#' },
    },
    {
        id: 2,
        title: 'Combien coûte une donation ?',
        href: 'https://clotere.com/le-calcul-des-frais-de-notaire-dune-donation/',
        description: "Cet article explore en détail les frais de notaire d’une donation, leur composition, et propose des exemples concrets pour mieux comprendre les coûts engagés.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/Frame-53.png?resize=600%2C403&ssl=1',
        category: { title: 'Prix d’une donation', href: '#' },
    },
    {
        id: 3,
        title: 'Donation ou succession ? Quel est le meilleur choix',
        href: 'https://clotere.com/donation-ou-succession-quel-est-le-meilleur-choix/',
        description: "Voici un comparatif entre la donation et la succession,leurs avantages, et comment le notaire peut vous aider à faire le bon choix.",
        imageUrl:
            'https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/choice-scaled.jpg?resize=600%2C952&ssl=1',
        category: { title: 'Donation', href: '#' },
    },
    // More posts...
]

function FaireDonation(args) {
    const navigate = useNavigate();
    return (
        <>

            <Navbar user={args.user} />

            <Container>
                {/** header */}
                <header class="flex flex-row flex-wrap justify-center items-center gap-10 bg-gradient-to-r from-teal-200 to-teal-500 p-10 rounded-lg">
                    <div className="w-full sm:basis-1/2  p-4">
                        <h1 className="font-normal text-transparent text-[50px] tracking-[0] leading-8 mb-8">
                            <span className="font-medium text-black">Faire une <span className="font-bold">donation</span>. </span>
                        </h1>
                        <p>
                            Voici les étapes clés de votre donation avec Clotere, pour préparer votre passage chez le notaire
                        </p>
                        <div className="flex flex-column justify-start gap-3">
                            <div className="flex items-center gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0"><b>Répondez à quelques questions</b> via un questionnaire</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0"><b>Complétez votre dossier</b> avec les documents nécessaires dans votre espace sécurisé</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0"><b>Choisissez votre notaire</b> pour gérer votre affaire, puis recevez votre acte de donation</p>
                                </div>
                            </div>
                            <div className="flex items-center  gap-2 relative flex-[0_0_auto]">
                                <CheckIcon className="!relative !w-4 !h-4" color="#000" />
                                <div className="relative w-fit mt-[-1.00px] [font-family:'Inter',Helvetica] font-normal text-black text-md tracking-[0] leading-[normal]">
                                    <p className="m-0"><b>Recevez votre acte de donation</b> en présentiel lors du rendez-vous ou dans votre espace sécurisé Clotere</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-0 lg:p-5">
                        <div className="overflow-hidden rounded-lg bg-white shadow">
                            <div className="p-3 sm:p-6 text-center">
                                <h3 className="font-normal text-[16px]  sm:text-[20px] tracking-[0] leading-8 mb-8">Quel est votre rôle dans la donation ?</h3>
                                <div className="flex flex-row gap-3 justify-center align-middle flex-wrap">

                                    <button
                                        type="button"
                                        className="p-2 text-lg w-full sm:w-auto sm:p-5 rounded bg-emerald-50  font-semibold text-emerald-600 shadow-sm hover:bg-gray-100"
                                        onClick={() => navigate('/inscription/donation')} >
                                        Donateur
                                    </button>
                                    <button
                                        type="button"
                                        className="p-2 text-lg w-full sm:w-auto sm:p-5 rounded bg-emerald-50 font-semibold text-emerald-600 shadow-sm hover:bg-gray-100"
                                        onClick={() => navigate('/inscription/donation')} >
                                        Bénéficiaire
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/** section SEO */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="w-full sm:basis-1/2  p-4">
                        <h2>Tout savoir sur votre donation</h2>
                        <p>
                            <strong>Faire une donation</strong>, peut parfois être compliqué. Beaucoup de paramètres sont à prendre en considération, comme votre situation familiale, votre patrimoine...etc.
                            <br /><br />
                            <strong>La donation</strong>, permet d’anticiper la transmission de son patrimoine de son vivant, tout en bénéficiant d’avantages fiscaux.
                            Pour <strong>réaliser une donation</strong> , il est souvent nécessaire de passer par un notaire, surtout si le don porte sur un bien immobilier. Celui-ci rédige <strong>l’acte de donation</strong> et conseille sur les démarches à suivre.
                            <br /><br />
                            Quelles sont les frais de donation ? Quels bien peux-t-on donner dans une donation ? Quels sont les avantages fiscaux d'une donation ?
                            Quelles sont les démarches à faire chez le notaire pour une donation ? On fait le point
                        </p>
                    </div>
                    <div className="w-full sm:basis-1/2  p-4 hidden sm:block">
                        <img alt="tout savoir sur la donation" src="https://i0.wp.com/clotere.com/wp-content/uploads/2024/10/clauses-suspensives.webp?resize=600%2C400&ssl=1" className="rounded overflow-hidden w-full" />
                    </div>
                </section>

                {/** section Blog */}
                <section class=" mt-4 mb-4 flex flex-row flex-wrap justify-center items-center">
                    <div className="bg-white py-24 sm:py-32 ">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto max-w-2xl text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bien comprendre toutes les étapes de <span className="font-bold text-[#1df36c]">votre donation</span></h2>
                                <p className="mt-2 text-lg leading-8 text-gray-600">
                                    Toutes toutes les réponses à vos questions sur la donation dans notre <a href="https://clotere.com/foire-aux-questions-des-notaires/" target="blank">foire aux questions donation</a>
                                </p>
                            </div>
                            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">
                                {posts.map((post) => (
                                    <article key={post.id} className="flex flex-col items-start justify-between overflow-hidden bg-white shadow sm:rounded-lg p-3">
                                        <div className="relative w-full">
                                            <img
                                                alt=""
                                                src={post.imageUrl}
                                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                            />
                                            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="max-w-xl">
                                            <div className="mt-8 flex items-center gap-x-4 text-sm">
                                                <p
                                                    className="relative z-10 rounded-full bg-emerald-50 px-3 py-1.5 font-medium text-emerald-600 hover:bg-gray-100"
                                                >
                                                    {post.category.title}
                                                </p>
                                            </div>
                                            <div className="group relative">
                                                <h3 className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600">
                                                    <a href={post.href} target='blank' className="mt-3 text-xl font-semibold leading-7 text-gray-900 group-hover:text-gray-600 no-underline">
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </a>
                                                </h3>
                                                <p className="mt-0 text-md leading-6 text-gray-600">{post.description}</p>
                                                <a href={post.href} target='blank' className="btn-main no-underline">Lire l'article</a>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/** section ccm **/}
                <section className="mt-20 mb-20">
                    <Container>
                        <div class="container mx-auto px-[15px] h-full">
                            <div class="flex flex-col justify-between items-start gap-[30px]">
                                <div class="py-2 px-4 rounded-[20px] text-center bg-emerald-500/30 text-emerald-500">
                                Faites votre donation simplement
                                </div>
                                <h3 class="text-black leading-[48px] text-[40px] lg:leading-[50px] lg:text-[45px] font-bold sm:max-w-[60%]">Votre donation avec Clotere, comment ça marche ?</h3>

                                <div class="grid lg:grid-cols-4 w-full gap-[10px] ">
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M4.848 2.771A49.144 49.144 0 0 1 12 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97-1.94.284-3.916.455-5.922.505a.39.39 0 0 0-.266.112L8.78 21.53A.75.75 0 0 1 7.5 21v-3.955a48.842 48.842 0 0 1-2.652-.316c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Répondez à quelques questions</h3>
                                            <p>Nous avons besoin de quelques informations sur vous et votre donation pour constituer votre dossier.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 1.5H5.625c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5Zm6.61 10.936a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 14.47a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                                                <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Complétez votre dossier</h3>
                                            <p>Ajoutez dans votre espace sécurisé les documents nécessaires à votre dossier chez le notaire.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">
                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Choisissez votre notaire</h3>
                                            <p>A vous de choisir votre notaire pour gérer votre dossier de donation. 100% à distance ou à proximité de chez vous c’est vous qui choisissez</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col justify-end items-start gap-[10px] bg-emerald-500/30 p-[20px] rounded-lg w-full h-auto relative overflow-hidden hover:card-hover">

                                        <div class="flex flex-col justify-end items-start gap-[10px] max-w-[253px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                                <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
                                            </svg>
                                            <h3 class="text-black font-bold text-[22px] leading-[22px]">Obtenez vos actes notariés</h3>
                                            <p>Faites vos rendez-vous de donation en visio ou présentiel, et obtenez directement notre plateforme vos actes de donation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>


            </Container>
            <BlocCta/>
            <Faq/>


            <Footer />
        </>
    );
}

export default FaireDonation;